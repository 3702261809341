import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppConfigModule } from './app.config';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		AppConfigModule,
		AppRoutingModule,  // Main routes for application
		CoreModule,        // Singleton objects (services, components that are loaded only once, etc.)
		SharedModule,      // Shared (multi-instance) objects
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule {}
