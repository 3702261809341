import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { WINDOW_PROVIDERS } from './services/window.service';
import { LightboxModule } from 'ngx-lightbox';

@NgModule({
	declarations: [
		NavbarComponent,
	],
	imports: [
		SharedModule,
		LightboxModule
	],
	exports: [
		NavbarComponent,
		LightboxModule
	],
	providers: [
		WINDOW_PROVIDERS
	]
})

export class CoreModule {}
