import { InjectionToken, NgModule } from '@angular/core';
import { IAppConfig } from '@shared/interfaces/IAppConfig';

export let APP_CONFIG = new InjectionToken<IAppConfig>("app.config");

export const AppConfig: IAppConfig = {
	activities: [
		{
			id: 'epiteszeti',
			title: 'Építészeti tervezés',
			description: '<ul><li>beruházások építészeti koncepciójának kidolgozása változatelemzéssel, költségbecsléssel</li>' +
				'<li>engedélyeztetés</li>' +
				'<li>kiviteli tervezés szakági tervezéssel</li>' +
				'<li>látványtervezés, BIM épületmodellezés</li></ul>'
		},
		{
			id: 'tartoszerkezet',
			title: 'Tartószerkezet tervezés',
			description: '<ul><li>statikai számítás, változatelemzés</li>' +
				'<li>vasbeton szerkezetek zsaluzási- és vasalási terveinek készítése</li>' +
				'<li>acél szerkezetek</li></ul>'
		},
		{
			id: 'muszaki',
			title: 'Műszaki ellenőrzés',
			description: '<ul><li>műszaki ellenőrzés a 191/2009 kormányrendeletben meghatározott szakmai feladatok ellátásával</li></ul>'
		}
	],
	members: [
		{
			name: 'Fehér Zsolt',
			position: 'Okleveles építész, műszaki ellenőr'
		},
		{
			name: 'Esztergályos Patrik',
			position: 'Építész'
		},
		{
			name: 'Németh István',
			position: 'Tartószerkezet tervező'
		}
	],
	references: [
		{
			id: 'hora',
			image: 'assets/img/references/Ho-RA-1.jpg',
			caption: '42 lakásos társasház tervezése'
		},
		{
			id: 'biogabona',
			image: 'assets/img/references/MICHLITS.jpg',
			caption: 'Biogabona feldolgozó üzem tervezése'
		},
		{
			id: 'rr-terasz',
			image: 'assets/img/references/RR-terasz.jpg',
			caption: 'Lakóház terasz átépítése'
		},
		{
			id: 'flextime',
			image: 'assets/img/references/Flextime1.jpg',
			caption: 'Acélszerkezetes csarnok gyártmánytervezése'
		},
		{
			id: 'huncargo',
			image: 'assets/img/references/Huncargo3.jpg',
			caption: 'Acélszerkezetes csarnok gyártmánytervezése'
		},
		{
			id: 'huncargo2',
			image: 'assets/img/references/Huncargo1.jpg',
			caption: 'Acélszerkezetes csarnok gyártmánytervezése'
		}
	]
};

@NgModule({
	providers: [{
		provide: APP_CONFIG,
		useValue: AppConfig
	}]
})
export class AppConfigModule { }
