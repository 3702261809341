import { Component, ElementRef, HostBinding, HostListener, Inject } from '@angular/core';
import { WINDOW } from '../../services/window.service';

interface NavigationLink {
	id: string;
	name: string;
}

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
	navigationLinks: NavigationLink[] = [
		{ id: 'about', name: 'Bemutatkozás' },
		{ id: 'services', name: 'Tevékenységeink' },
		{ id: 'team', name: 'Csapat' },
		{ id: 'references', name: 'Referenciák' },
		{ id: 'contact', name: 'Kapcsolat' }
	];

	@HostBinding('class.scrolled') isScrolled: boolean;
	@HostListener('window:scroll', [])
	onWindowScroll(): void {
		this.isScrolled = window.scrollY > 0;
	}

	constructor(
		private element: ElementRef,
		@Inject(WINDOW) private window: Window
	) {}

	scrollTo(sectionId: string): void {
		const section: HTMLElement = document.getElementById(sectionId);
		const navbarHeight: number = this.element.nativeElement.offsetHeight;
		if (section) {
			this.window.scroll({
				top: section.offsetTop - navbarHeight,
				left: 0,
				behavior: 'smooth'
			});
		}
	}
}
