import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { APP_CONFIG } from '../../app.config';
import { WINDOW } from '../../core/services/window.service';
import { Lightbox } from 'ngx-lightbox';
import { IReference } from '@shared/interfaces/IReference';
import { IActivity } from '@shared/interfaces/IActivity';
import { IAppConfig } from '@shared/interfaces/IAppConfig';
import { IAlbumItem } from '@shared/interfaces/IAlbumItem';
import { IMember } from '@shared/interfaces/IMember';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	references: IReference[];
	activities: IActivity[];
	members: IMember[];
	private _albums: IAlbumItem[] = [];

	@HostListener('window:resize') onWindowResize(): void {
		this.setIntroHeight();
	}

	constructor(
		private element: ElementRef,
		@Inject(APP_CONFIG) private config: IAppConfig,
		@Inject(WINDOW) private window: Window,
		private _lightbox: Lightbox
	) {
		this.activities = config.activities;
		this.members = config.members;
		this.references = config.references;

		for (let i = 0; i < this.references.length; i++) {
			const albumItem: IAlbumItem = {
				src: this.references[i].image,
				caption: this.references[i].caption,
				thumb: this.references[i].image
			};
			this._albums.push(albumItem);
		}
	}

	ngOnInit(): void {
		this.setIntroHeight();
	}

	open(index: number): void {
		this._lightbox.open(this._albums, index);
	}

	setIntroHeight(): void {
		const height: number = this.window.innerHeight;
		this.element.nativeElement.querySelector('#welcome.section').style.height = (height / 3 * 2) + 'px';
	}

}
