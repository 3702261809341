import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './pages/home/home.component';

@NgModule({
	imports: [
		SharedModule,
		RouterModule.forRoot([
			{
				path: '',
				component: HomeComponent,
				pathMatch: 'full'
			},
			{
				path: '**',
				redirectTo: ''
			}
		])
	],
	exports: [
		HomeComponent
	],
	declarations: [
		HomeComponent
	],
	entryComponents: [
		HomeComponent
	]
})
export class AppRoutingModule {}
